<template>
  <div>

  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },

    computed: {},

    watch: {},

    created() {

    },

    mounted() {

    },

    methods: {}
  }
</script>

<style scoped>

</style>
